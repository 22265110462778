const INITIAL_STATE = {
  todo: [
    {
      id: null,
      todo: '',
      completed: false,
    },
  ],
};
export default INITIAL_STATE;
